<template>
  <div>
    <loading-overlay :active="loadingOverlay" :is-full-page="true" loader="bars" />
    <CModalExtended
      :title="titleModal"
      color="dark"
      :show.sync="showModal"
      :closeOnBackdrop="false"
      size="lg"
      :class="classModal"
    >
      <CRow>
        <CCol sm="12">
          <CCollapse :show="collapse">
            <CRow>
              <CCol sm="12" v-if="collapseInt==0">
                <AddRol :value="newUnit" :clearCollapse="ActCollapse"  @child-refresh="cerrarModal(0)" />
              </CCol>
              <CCol sm="12" v-if="collapseInt==1">
                <AddCompany :value="newUnit" @child-refresh="cerrarModal(1)" />
              </CCol>
              <CCol sm="12" v-if="collapseInt==2">
                <AddUbicacion :value="newUnit" @child-refresh="cerrarModal(2)" />
              </CCol>
            </CRow>
          </CCollapse>
        </CCol>
      </CRow>
      <template #footer-wrapper>
        <!--<CButton color="dark" @click="cerrarModal(true)">
          <CIcon name="cil-chevron-circle-left-alt" />&nbsp; {{$t('button.cancel')}}
        </CButton>-->
       <label></label>
      </template>
    </CModalExtended>
  </div>
</template>
<script>
import UpperCase  from '@/_validations/uppercase-directive';
import General from '@/_mixins/general';
import AddCompany from '@/pages/empresa/add-empresa';
import AddUbicacion from '@/pages/estiba/ubicacion/add-ubicacion';
import AddRol from '@/pages/estiba/stowageRoles/add-role';

function cerrarModal(valor) {
  this.$emit('child-refresh', valor);
  this.limpiarDatos();
  this.showModal=false;
}

function limpiarDatos() {
    this.titleModal= '';
    this.tituloCollapse= '';
    this.collapse= false;
    this.collapseInt= 2;
    this.newUnit= false;
    this.global= 0;
    this.ActCollapse ='';
    this.classModal = '';
}

function data() {
  return {
    showModal: false,
    titleModal: '',
    loadingOverlay: false,
    tituloCollapse: '',
    collapse: false,
    collapseInt: 0,
    newUnit: false,
    global: 0,
    ActCollapse: '',
    classModal: '',
  };
}

export default {
  name: 'modal-recurso',
  data,
  props: {
    modal: null,
  },
  mixins: [General],
  directives: UpperCase,
  components: {
    AddUbicacion,
    AddCompany,
    AddRol,
  },
  methods: {
    cerrarModal,
    limpiarDatos,
  },
  watch: {
    modal: async function () {
      if (this.modal!== false) {
        this.showModal = true;
        switch (this.modal) {
          case 0:{
            this.titleModal = this.$t('label.nuevo')+' '+ this.$t('label.role');
            this.classModal = 'modal-extended';
            this.ActCollapse = 0;
          break;
          }case 1:{
            this.titleModal = this.$t('label.nueva')+' '+ this.$t('label.company');
            this.classModal = '';
          break;
          }case 2:{
            this.titleModal = this.$t('label.nueva')+' '+ this.$t('label.ubication');
            this.classModal = 'modal-extended';
          break;
          }
        }
        this.collapseInt = this.modal;
        this.global = this.collapseInt;
        this.collapse = true;
        this.$emit('cerrarModal');
      }
    }
  },
};
</script>
<style scope>
  .largo {
    width: 112% !important;
  }  
  .w-85 {
    width: 85% !important;
  }
  .alto {
    min-height: 600px;
    overflow-x: auto;
  }
</style>
